"use client";

import React from "react";
import { assets } from "@koble/assets/assets";
import { Button } from "antd";

import CommonEmptyContainer from "./CommonEmptyContainer";

// x-release-please-start-version
const CommonErrorPage = ({ error }: { error: Error & { digest?: string } }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CommonEmptyContainer
        img={assets.KOBOT_SAD_LARGE}
        title="¡Oops! Algo salió mal"
        subtitle={
          <div>
            Lo sentimos, algo salió mal. Por favor, inténtalo de nuevo.
            <br />
            <br />
            {error.message} (V1.39.0)
          </div>
        }
        alt="Error"
        extraContent={
          <Button
            type="link"
            onClick={() => {
              window.location.reload();
            }}
          >
            Intentar de nuevo
          </Button>
        }
      />
    </div>
  );
};

export default CommonErrorPage;
